<template>
  <div>
    <van-nav-bar
      title="家庭成员"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <table>
      <tr class="thead">
        <td>人编码</td>
        <td>姓名</td>
        <td>年龄</td>
        <td>与户主关系</td>
        <td>性别</td>
        <td></td>
      </tr>
      <tr
        class="inform"
        v-for="(v, i) in memberArr"
        :key="i"
        @click="goDetails(v)"
      >
        <td>{{ v.familyMemberCode }}</td>
        <td>{{ v.name }}</td>
        <td>{{ v.age }}</td>
        <td>
          {{
            common.gettypeListLabelFn(
              "holdcontactList",
              v.householdRelationship
            )
          }}
        </td>
        <td>
          {{ common.gettypeListLabelFn("sexList", v.sex) }}
        </td>
        <td><van-icon name="arrow" /></td>
      </tr>
    </table>
    <div class="opinion" @click="goOpinion">意见及诉求</div>
  </div>
</template>

<script>
import { findtroubleshootFamily } from "@/api/atp.js";
export default {
  data() {
    return {
      memberArr: [],
      searchObj: {
        idCard: JSON.parse(sessionStorage.getItem("postForm")).idCard,
      },
    };
  },
  mounted() {
    this.getAll();
  },
  methods: {
    getAll() {
      findtroubleshootFamily(this.searchObj)
        .then((res) => {
          if (res.status === 200) {
            this.memberArr = res.data.memberList;
            let token = sessionStorage.getItem("token");
            if (!token) {
              for (var i = 0; i < this.memberArr.length; i++) {
                if (this.memberArr[i].name.length == 3) {
                  this.memberArr[i].name =
                    this.memberArr[i].name.substring(0, 1) +
                    "*" +
                    this.memberArr[i].name.substring(
                      this.memberArr[i].name.length - 1,
                      this.memberArr[i].name.length
                    );
                } else if (this.memberArr[i].name.length == 2) {
                  this.memberArr[i].name =
                    this.memberArr[i].name.substring(0, 1) + "*";
                }
                // 证件号码隐藏
                var idCardStr = "";
                for (var k = 0; k < this.memberArr[i].idCard.length - 8; k++) {
                  idCardStr += "*";
                }
                this.memberArr[i].idCard =
                  this.memberArr[i].idCard.substring(0, 4) +
                  idCardStr +
                  this.memberArr[i].idCard.substring(
                    this.memberArr[i].idCard.length - 4,
                    this.memberArr[i].idCard.length
                  );
                this.memberArr[i].phone =
                  this.memberArr[i].phone.substring(0, 3) +
                  "****" +
                  this.memberArr[i].phone.substring(
                    this.memberArr[i].phone.length - 4,
                    this.memberArr[i].phone.length
                  );
              }
            }
          } else {
            this.$toast("查询数据失败");
          }
        })
        .catch(() => {
          this.$toast("获取基本信息失败");
        });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    goOpinion() {
      this.$router.push("/opinion");
    },
    goDetails(v) {
      this.$router.push({ path: "/familyMemberDetails", query: { v: v } });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
.thead {
  font-weight: bold;
  color: #666;
  height: 0.4rem;
  line-height: 0.4rem;
  border-bottom: 2px solid #eee;
}
.inform {
  font-size: 0.12rem;
  height: 0.4rem;
  line-height: 0.4rem;
  border-bottom: 1px solid #eee;
}
td {
  text-align: center;
}
.opinion {
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  background-color: #f0ffff;
  color: #1e90ff;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
}
/* vant 导航栏自适应 */
.van-nav-bar {
  height: 0.46rem;
}
>>> .van-nav-bar__content {
  height: 100%;
}
>>> .van-nav-bar__title {
  height: 100%;
  line-height: 0.46rem;
  font-size: 0.16rem;
}
>>> .van-icon-arrow-left,
>>> .van-nav-bar__text {
  font-size: 0.14rem;
}
</style>