import axios from 'axios'
import global from '@/assets/js/global'
// import Vue from 'vue'
// import router from '../router/index'
// 配置axios实例
let http = axios.create({
  baseURL: global.atpPath
})
// request拦截器
http.interceptors.request.use(
  config => {
    // let expires = sessionStorage.getItem('token') ? sessionStorage.getItem('expires') : ''
    // let lastAccessTime = sessionStorage.getItem('token') ? sessionStorage.getItem('lastAccessTime') : ''
    // let currentTime = new Date().getTime() / 1000
    // if (Number(currentTime) - Number(lastAccessTime) > Number(expires)) {
    //   Vue.prototype.$alert('登录信息已失效，请重新登录', {
    //     confirmButtonText: '确定',
    //     callback: action => {
    //       sessionStorage.clear()
    //       router.push('/login')
    //       history.go(0)
    //     }
    //   })
    //   return
    // } else {
    let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '1'
    config.headers['X-Auth-Token'] = token
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    // }
    return config
  },
  err => {
    return Promise.reject(err)
  })
// 请求结束后
http.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    // switch (error.response.status) {
    //   case 406:
    //     Vue.prototype.$alert('登录信息已失效，请重新登录', {
    //       confirmButtonText: '确定',
    //       callback: action => {
    //         sessionStorage.clear()
    //         router.push('/login')
    //         history.go(0)
    //       }
    //     })
    //     break
    //   case 401:
    //     Vue.prototype.$alert('登录信息已失效，请重新登录', {
    //       confirmButtonText: '确定',
    //       callback: action => {
    //         sessionStorage.clear()
    //         router.push('/login')
    //         history.go(0)
    //       }
    //     })
    //     break
    // }
  }

  return Promise.reject(error)
})
export { http }
// 查询基本情况/家庭成员
export const findtroubleshootFamily = params => {
  console.log(params)
  return http.get('/troubleshoot/v1/family?idCard=' + params.idCard).then(response => response)
}
// 根据帮扶人ID查询
export const cadreManagerPage = params => {
  return http.get('/cadre/v1/declare/page?type=3&userId=' + params.helperId, params).then(response => response)
}
// 获取成员(new)
export const findmembersNew = params => {
  return http.get('/anti-poverty/troubleshoot/v1/family/members', { params: params }).then(response => response)
}
// 政策红利查询
export const dividendPolicySearch = params => {
  return http.get('/anti-poverty/policy/dividend/v1/search', { params: params }).then(response => response)
}